import "./ArticleListUnitComponent.sass";
import { EyeOutlined, FieldTimeOutlined } from "@ant-design/icons";

export type ArticleEle = {
  title: string;
  short_id: string;
  create: string;
  view_count: string;
};

function ArticleListUnitComponent(articleEle: ArticleEle) {
  return (
    <article className="article-unit">
      <a
        className="article-unit-a"
        href={"https://md.shellmiao.com/s/" + articleEle.short_id}
        target="view_window"
      >
        <h2 className="article-unit-h2">{articleEle.title}</h2>
      </a>
      <div className="article-unit-info">
        <span>
          <FieldTimeOutlined />
          &nbsp;
          {articleEle.create.slice(4, 16)}
        </span>
        &nbsp;
        <span>
          <EyeOutlined />
          &nbsp;
          {articleEle.view_count}
        </span>
      </div>
    </article>
  );
}

export default ArticleListUnitComponent;

import { useDispatch, useSelector } from "react-redux";
import {
  fetchCodimd,
  selectAllCodimd,
  selectCodimdState,
} from "../../Redux/CodimdReducer";
import ArticleListUnitComponent from "./ArticleListUnitComponent";
import { useEffect } from "react";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Status } from "../../Redux/Store";
import { selectWidth } from "../../Redux/WidthReducer";

function getList(codimdStatus: Status, dispatch: Dispatch<AnyAction>): void {
  if (codimdStatus === "idle") {
    dispatch<any>(fetchCodimd());
  }
}

function ArticleList() {
  const width = useSelector(selectWidth);

  const dispatch = useDispatch();

  const codimd = useSelector(selectAllCodimd);

  const codimdStatus = useSelector(selectCodimdState);

  useEffect(() => {
    getList(codimdStatus, dispatch);
  }, [codimdStatus, dispatch]);

  const articleList = codimd.map((ele, index) => (
    <div key={index}>
      <ArticleListUnitComponent {...ele} />
    </div>
  ));

  let content;
  if (codimdStatus === "loading") {
    content = <div>{articleList}加载中</div>;
  } else if (codimdStatus === "failed") {
    content = <div>{articleList}加载失败，请稍后重试</div>;
  } else {
    content = <div>{articleList}</div>;
  }

  return (
    <div
      style={{
        maxWidth: `${width < 1000 ? 600 : width / 2}px`,
        margin: "0 auto",
      }}
    >
      {content}
      <div style={{
        textAlign:"center",
        marginBottom:"36px",
        fontSize: "12px",
        color: "#5e5e5e7b",
    }}>
        <span>
          Copyright ©2023 Shellmiao<br></br>
        <a 
          href={"https://beian.miit.gov.cn/#/Integrated/index"} 
          style={{
            WebkitTapHighlightColor:"transparent",
            color: "#5e5e5e7b",
            textDecoration:"none"
          }}
        >
          京ICP备2020046305号-1
        </a>
        </span>
      </div>
    </div>
  );
}

export default ArticleList;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ArticleEle } from "../Components/Article/ArticleListUnitComponent";
import { getCodimdList } from "../Utils/Axios";
import { state, Status } from "./Store";

export type CodimdState = {
  data: [ArticleEle];
  status: Status;
  error: string | null;
};

export const codimdState = {
  data: [] as unknown as [ArticleEle],
  status: "idle",
  error: "",
};

export const fetchCodimd = createAsyncThunk("posts/fetchCodimd", async () => {
  const response = await getCodimdList();
  return response.data;
});

export const codimdSlice = createSlice({
  name: "codimd",
  initialState: codimdState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCodimd.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCodimd.fulfilled, (state, action) => {
        state.status = "succeeded";
        (action.payload as [ArticleEle]).forEach((elePayload) => {
          let flag = false;
          for (let i = 0; i < state.data.length; i++) {
            if (state.data[i].short_id === elePayload.short_id) {
              flag = true;
              break;
            }
          }
          if (!flag) {
            state.data.push(elePayload);
          }
        });
      })
      .addCase(fetchCodimd.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as string;
      });
  },
});

export const selectAllCodimd = (state: state) => state.codimd.data;

export const selectCodimdState = (state: state) => state.codimd.status;

export default codimdSlice.reducer;

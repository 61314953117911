import axios from "axios";

const codimdUrl = "https://blogbackcodimd.shellmiao.com";
const picUrl = "https://blogbackpic.shellmiao.com";

type DefaultRes = {
  // `data` 由服务器提供的响应
  data: {};
  // `status` 来自服务器响应的 HTTP 状态码
  status: 200;
  // `statusText` 来自服务器响应的 HTTP 状态信息
  statusText: "OK";
  // `headers` 服务器响应的头
  headers: {};
  // `config` 是为请求提供的配置信息
  config: {};
  // 'request'
  // `request` is the request that generated this response
  // It is the last ClientRequest instance in node.js (in redirects)
  // and an XMLHttpRequest instance the browser
  request: {};
};

export async function getCodimdList(): Promise<DefaultRes> {
  return axios.get(codimdUrl + "/get_my_notes");
}

export async function getPicList(): Promise<DefaultRes> {
  return axios.get(picUrl + "/get_my_pics");
}

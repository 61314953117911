import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { PhotoProvider } from "react-photo-view";
import { useDispatch, useSelector } from "react-redux";
import { fetchPic, selectAllPic, selectPicState } from "../../Redux/PicReducer";
import { Status } from "../../Redux/Store";
import { selectWidth } from "../../Redux/WidthReducer";
import PicComponent, { PicEle } from "./PicComponent";
import "./PicList.sass";

function getList(picStatus: Status, dispatch: Dispatch<AnyAction>): void {
  if (picStatus === "idle") {
    dispatch<any>(fetchPic());
  }
}

function PicList() {
  const width = useSelector(selectWidth);

  const dispatch = useDispatch();

  const picListData = useSelector(selectAllPic) as unknown as [PicEle];

  const picStatus = useSelector(selectPicState);

  useEffect(() => {
    getList(picStatus, dispatch);
  }, [picStatus, dispatch]);

  let picList;

  if (width <= 1400) {
    picList = picListData.map((ele, index) => {
      const data = { picEle: ele, width: (width * 7) / 8 };
      return (
        <div key={index}>
          <PicComponent {...data} />
        </div>
      );
    });
  } else {
    let left = 0,
      right = 0;
    let leftPic = [],
      rightPic = [];
    for (let i = 0; i < picListData.length; i++) {
      const data = { picEle: picListData[i], width: (width * 3) / 8 };
      const delta =
        Number(picListData[i].height) *
        (data.width / Number(picListData[i].width));
      if (right < left) {
        rightPic.push(
          <div key={i}>
            <PicComponent {...data} />
          </div>
        );
        right += delta;
      } else {
        leftPic.push(
          <div key={i}>
            <PicComponent {...data} />
          </div>
        );
        left += delta;
      }
    }
    picList = (
      <div>
        <div id="left" style={{ marginLeft: `${width / 10}px` }}>
          {leftPic}
        </div>
        <div id="right" style={{ marginRight: `${width / 10}px` }}>
          {rightPic}
        </div>
      </div>
    );
  }

  return (
    <div>
      <PhotoProvider>{picList}</PhotoProvider>
    </div>
  );
}
export default PicList;

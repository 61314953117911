import { configureStore } from "@reduxjs/toolkit";
import codimdReducer, { CodimdState } from "./CodimdReducer";
import PicReducer, { PicState } from "./PicReducer";
import WidthReducer from "./WidthReducer";

export type Status = "idle" | "loading" | "succeeded" | "failed";

export type state = {
  codimd: CodimdState;
  pic: PicState;
  width: number;
};

export default configureStore({
  reducer: {
    codimd: codimdReducer,
    pic: PicReducer,
    width: WidthReducer,
  },
});

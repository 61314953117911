import { Link } from "react-router-dom";
import "./HeaderComponent.sass";

function HeaderComponent() {
  return (
    <div className="header-div">
      <Link to={"/"}>
        <img
          className="avatar"
          src="https://pic.shellmiao.com/2022/06/06/629e1c471aa3f.jpeg"
          alt="avatar"
        />
      </Link>
      <h1 className="header-id-h1">Shellmiao</h1>
      <p className="header-p">
        🇨🇳 <b>China Beijing</b>
      </p>
    </div>
  );
}

export default HeaderComponent;

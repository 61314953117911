import { createSlice } from "@reduxjs/toolkit";
import { state } from "./Store";

export const widthSlice = createSlice({
  name: "width",
  initialState: document.body.clientWidth,
  reducers: {
    setWidth(state, action) {
      return (state = action.payload);
    },
  },
});

export const selectWidth = (state: state) => state.width;

export const { setWidth } = widthSlice.actions;

export default widthSlice.reducer;

import { useState } from "react";
import "./MenuComponent.sass";
import { ReadOutlined, PictureOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function MenuComponent() {
  const [ifActive, setIfAvtive] = useState<Boolean>(false);

  const activeFlag = ifActive ? " active" : "";

  const menu = (
    <div className={activeFlag}>
      <div id="menu-article-div">
        <Link to={"/"}>
          <div id="article-icon">
            <ReadOutlined />
          </div>
        </Link>
      </div>
      <div id="menu-pic-div">
        <Link to={"/pic"}>
          <div id="pic-icon">
            <PictureOutlined />
          </div>
        </Link>
      </div>
    </div>
  );
  return (
    <div id="menu-div">
      <div id="menu-hamburger-div">
        <div
          id="hamburger-icon"
          onClick={() => {
            setIfAvtive(!activeFlag);
          }}
          className={activeFlag}
        >
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </div>
      </div>
      {menu}
    </div>
  );
}
export default MenuComponent;

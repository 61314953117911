import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PicEle } from "../Components/Pic/PicComponent";
import { getPicList } from "../Utils/Axios";
import { state, Status } from "./Store";

export type PicState = {
  data: [];
  status: Status;
  error: string | null;
};

export const picState = {
  data: [] as unknown as [PicEle],
  status: "idle",
  error: "",
};

export const fetchPic = createAsyncThunk("posts/fetchPic", async () => {
  const response = await getPicList();
  return response.data;
});

export const picSlice = createSlice({
  name: "pic",
  initialState: picState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPic.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPic.fulfilled, (state, action) => {
        state.status = "succeeded";
        (action.payload as [PicEle]).forEach((elePayload) => {
          let flag = false;
          for (let i = 0; i < state.data.length; i++) {
            if (state.data[i].id === elePayload.id) {
              flag = true;
              break;
            }
          }
          if (!flag) {
            state.data.push(elePayload);
          }
        });
      })
      .addCase(fetchPic.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message as string;
      });
  },
});

export const selectAllPic = (state: state) => state.pic.data;

export const selectPicState = (state: state) => state.pic.status;

export default picSlice.reducer;

import React from "react";
import "./Global/Global.sass";
import "react-photo-view/dist/react-photo-view.css";
import HeaderComponent from "./Components/HeaderComponent";
import ArticleList from "./Components/Article/ArticleList";
import MenuComponent from "./Components/MenuComponent";
import { Routes, Route } from "react-router-dom";
import NotFoundComponent from "./Components/NotFoundComponent";
import PicList from "./Components/Pic/PicList";
import { useDispatch, useSelector } from "react-redux";
import { selectWidth, setWidth } from "./Redux/WidthReducer";

function App() {
  const dispatch = useDispatch();

  const width = useSelector(selectWidth);

  const handleResize = (e: any) => {
    dispatch(setWidth(e?.target?.innerWidth));
  };

  window.addEventListener("resize", handleResize);

  return (
    <div className="App" style={{ maxWidth: `${width}px` }}>
      <HeaderComponent />
      <MenuComponent />
      <Routes>
        <Route path="/" element={<ArticleList />}></Route>
        <Route path="/pic" element={<PicList />}></Route>
        <Route path="*" element={<NotFoundComponent />} />
      </Routes>
    </div>
  );
}

export default App;

import { FileExclamationOutlined } from "@ant-design/icons";
import "./NotFoundComponent.sass";

function NotFoundComponent() {
  return (
    <div className="not-found-div">
      <FileExclamationOutlined />
      &nbsp; 什么也没找到呢,点击喵喵返回主页
    </div>
  );
}

export default NotFoundComponent;

import { PhotoView } from "react-photo-view";
import "./PicComponent.sass";

export type PicEle = {
  id: string;
  path: string;
  name: string;
  width: string;
  height: string;
  create: string;
};

function PicComponent(prop: { picEle: PicEle; width: number }) {
  const url =
    "https://pic.shellmiao.com/" + prop.picEle.path + "/" + prop.picEle.name;
  return (
    <div className="pic-component-div">
      <PhotoView src={url}>
        <img
          src={url}
          alt=""
          className="pic-component-img"
          style={{ width: `${prop.width}px` }}
        />
      </PhotoView>
    </div>
  );
}
export default PicComponent;
